<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div class="uk-inline">
                    <div class="uk-flex uk-width-medium">
                        <button class="uk-button uk-button-default uk-width-expand" style="background: white" type="button" id="filter">{{filterText}}Filter</button>
                        <button class="uk-button-small uk-button-danger uk-width-auto" type="button" v-if="filterText!=0" @click="resetFilter"><span uk-icon="icon: close"></span></button>
                    </div>
                </div>
                <div id="dropdown-filter" class="uk-width-auto" uk-dropdown='mode: click;toggle: #filter'>
                    <div class="uk-dropdown-grid" uk-grid>
                        <div>
                            <ul class="uk-nav uk-dropdown-nav">
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="form-fullname">Fullname</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" id="form-fullname" type="text" v-model="meta.fullname">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="form-phone">Phone Number</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" id="form-phone" type="text" v-model="meta.phone_number">
                                    </div>
                                </div>
                                <div class="uk-margin">
                                    <label class="uk-form-label" for="form-email">Email</label>
                                    <div class="uk-form-controls">
                                        <input class="uk-input" id="form-email" type="text" v-model="meta.email">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <div>
                            <ul class="uk-nav uk-dropdown-nav">
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.educations"> Pendidikan</label>
                                </div>
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.work_experiences"> Pengalaman Kerja</label>
                                </div>
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.skills"> Keterampilan</label>
                                </div>
                            </ul>
                        </div>
                        <div>
                            <ul class="uk-nav uk-dropdown-nav">
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.doc_skck"> SKCK</label>
                                </div>
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.doc_ktp"> KTP</label>
                                </div>
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.doc_sim"> SIM</label>
                                </div>
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.doc_ijazah"> Ijazah</label>
                                </div>
                            </ul>
                        </div>
                        <div>
                            <ul class="uk-nav uk-dropdown-nav">
                                <div class="uk-margin">
                                    <label><input class="uk-checkbox" type="checkbox" v-model="meta.kormo"> Kormo</label>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <button class="uk-button uk-button-primary uk-width-expand" type="button" @click="setUserMeta">Search</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-width-medium">Fullname</th>
                            <th class="uk-text-center uk-width-small">Email</th>
                            <th class="uk-text-center uk-width-1-6">Phone</th>
                            <th class="uk-text-center">Pendidikan</th>
                            <th class="uk-text-center">Pengalaman<br>Kerja</th>
                            <th class="uk-text-center">Keterampilan</th>
                            <th class="uk-text-center">Dokumen<br>Pendukung</th>
                            <th class="uk-text-center">Kormo</th>
                            <th class="uk-text-center">Updated By</th>
                            <th class="uk-text-center uk-width-1-5">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="10"/>
                    <tbody v-else-if="users.docs.length>0">
                        <tr v-for="(user, i) in users.docs" :key="i">
                            <td>{{user.fullname || '-'}}</td>
                            <td>{{user.email || '-'}}</td>
                            <td>{{user.phone_number || '-'}}</td>
                            <td>
                                <img :src="`${images}/check-true.svg`" style="width:20px" v-if="user.educations">
                                <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td>
                                <img :src="`${images}/check-true.svg`" style="width:20px" v-if="user.work_experiences">
                                <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td>
                                <img :src="`${images}/check-true.svg`" style="width:20px" v-if="user.skills">
                                <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td>{{user.doc_count}}/4</td>
                            <td>
                                <img :src="`${images}/check-true.svg`" style="width:20px" v-if="user.kormo_id">
                                <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td>{{ user.updated_by ? user.updated_by.fullname ? user.updated_by.fullname : '-' : '-' }}</td>
                            <td>
                                <button
                                    style="width:100px; cursor:pointer; height:30px; background:#333747"
                                    class="uk-button-primary"
                                    v-clipboard:copy="user._id"
                                    v-clipboard:success="onCopy">
                                    Copy Id
                                </button><br>
                                <button
                                    style="width:100px; cursor:pointer; height:30px"
                                    class="uk-button-default uk-margin-small-top"
                                    @click="detailUser(user._id)"
                                    v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])">
                                    Detail
                                </button><br>
                                <button
                                    style="width:100px; cursor:pointer; height:30px"
                                    class="uk-button-danger uk-margin-small-top"
                                    @click="delUser(user)"
                                    v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])">
                                    Delete
                                </button>
                                <button
                                    style="width:100px; cursor:pointer; height:30px"
                                    class="uk-button-success uk-margin-small-top"
                                    @click="inviteUser(user)"
                                    v-if="!consist(user_cred.roles, ['admin-bd','user-management','user-marketing'])">
                                    Invite
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="10"/>
                </table>
            </div>
            <pagination
                :total-data="users.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="formUserModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">User Form</h2>
            </div>
            <div class="uk-modal-body">

              <div class="uk-margin">
                <label class="uk-form-label">Full Name <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('fullname')}"
                  name="fullname"
                  type="text"
                  v-model="userForm.fullname"
                  v-validate="'required'"
                  placeholder="Full Name">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-small-right">Login via:</label>
                <label class="uk-form-label uk-margin-small-right">
                  <input
                    class="uk-radio"
                    name="login_via"
                    type="radio"
                    v-bind:value="true"
                    v-model="userForm.login_via_email"
                  >
                    Email
                </label>
                <label class="uk-form-label uk-margin-small-right">
                  <input
                    class="uk-radio"
                    name="login_via"
                    type="radio"
                    v-bind:value="false"
                    v-model="userForm.login_via_email"
                  >
                    Phone number
                </label>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Email <b v-if="userForm.login_via_email" class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('email')}"
                  name="email"
                  type="email"
                  v-model="userForm.email"
                  v-validate="{required: userForm.login_via_email}"
                  placeholder="Email">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('email')">
                  {{ errors.first('email') }} Switch login mode to make this field optional.
                </span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Phone Number <b v-if="!userForm.login_via_email" class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('phone_number')}"
                  name="phone_number"
                  type="text"
                  v-model="userForm.phone_number"
                  v-validate="{required: !userForm.login_via_email}"
                  placeholder="Phone Number">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('phone_number')">
                  {{ errors.first('phone_number') }} Switch login mode to make this field optional.
                </span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label"><span v-if="formIsEdit">Change </span>Password <b v-if="!formIsEdit" class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('password')}"
                  name="password"
                  type="password"
                  v-model="userForm.password"
                  v-validate="{required: !formIsEdit}"
                  placeholder="Password">
                <span v-if="!formIsEdit" class="uk-text-small uk-text-danger" v-show="errors.has('password')">{{ errors.first('password') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Role <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="userForm.roles"
                  placeholder="Select Role"
                  label="name"
                  name="role"
                  track-by="name"
                  v-validate="'required'"
                  :options="role_options">
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('role')">{{ errors.first('role') }}</span>
              </div>

              <div v-if="userForm.roles.name==='Candidate'" class="uk-margin">
                <label class="uk-form-label">Partners</label>
                <multiselect
                  v-model="userForm.partner_ids"
                  placeholder="Type to search company partner"
                  label="name"
                  name="partner"
                  track-by="name"
                  :options="partner_options"
                  :multiple="true"
                  :close-on-select="false"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setPartnerValues"
                >
                </multiselect>
              </div>

              <div v-if="userForm.roles.name==='Employer'" class="uk-margin">
                <label class="uk-form-label">Company</label>
                <multiselect
                  v-model="userForm.company_id"
                  placeholder="Type to search company"
                  label="name"
                  name="company"
                  track-by="name"
                  :options="company_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setCompanyValues"
                >
                </multiselect>
              </div>

              <div v-if="userForm.roles.name==='Employer'" class="uk-margin">
                <label class="uk-form-label">Company Role</label>
                <multiselect
                  v-model="userForm.client_role_id"
                  placeholder="Type to search company role"
                  label="name"
                  name="company"
                  track-by="name"
                  :options="internal_role_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setRoleValues"
                >
                </multiselect>
              </div>

              <div v-if="userForm.roles.name==='Employer'" class="uk-margin">
                <label class="uk-form-label">Office (Property)</label>
                <multiselect
                  v-model="userForm.client_office_id"
                  placeholder="Type to search property"
                  label="name"
                  name="property"
                  track-by="name"
                  :options="office_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setPropertyValues"
                >
                </multiselect>
              </div>

              <div v-if="formIsEdit" class="uk-margin">
                <label class="uk-form-label">Verification Code <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('verification_code')}"
                  name="notes"
                  type="text"
                  v-model="userForm.verification_code"
                  placeholder="Verification Code">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('verification_code')">{{ errors.first('verification_code') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Notes</label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('notes')}"
                  name="notes"
                  type="text"
                  v-model="userForm.notes_superadmin"
                  placeholder="Notes">
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">
                  <input
                    class="uk-checkbox uk-margin-small-right"
                    name="user_verified"
                    type="checkbox"
                    v-model="userForm.user_verified"
                  >
                    User Verified
                </label>
              </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary" type="button" @click="saveUser">Save</button>
            </div>
          </div>
        </div>

        <div id="formDetailModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Delete User</h2>
                </div>
                <div class="uk-modal-body">
                  <p>Are you sure to delete this user?</p>
                  <p>Name: <b>{{userForm.fullname}}</b></p>
                  <p>Email: <b>{{userForm.email}}</b></p>
                  <p>Phone Number: <b>{{userForm.phone_number ? userForm.phone_number : '-'}}</b></p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger" type="button" @click="markDelete">Delete</button>
                </div>
            </div>
        </div>

        <!-- Invite Modal-->
        <div id="formInviteModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideInviteModal"></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Invite</h2>
            </div>
            <div class="uk-modal-body">

              <div class="uk-margin">
                <label class="uk-form-label">Invite user to group <b class="uk-text-danger">*</b></label>
                <multiselect
                    v-model="inviteForm.group_id"
                    placeholder="Select group"
                    :class="{'uk-form-danger': errors.has('group')}"
                    label="name"
                    name="group"
                    track-by="_id"
                    :options="group_options"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :loading='isFetching'
                    :internal-search="false"
                    :options-limit="50"
                    @search-change="setGroupValues"
                    v-validate="'required'"
                >
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('group')">{{ errors.first('group') }}</span>
              </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary" :disabled="!inviteForm.group_id" type="button" @click="saveInvite">Save</button>
            </div>
          </div>
        </div>
        <!-- End Invite Modal-->
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    data() {
        return {
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            meta: {
                email: '',
                phone_number: '',
                fullname: '',
                work_experiences: false,
                educations: false,
                skills: false,
                doc_ijazah: false,
                doc_ktp: false,
                doc_skck: false,
                doc_sim: false,
                mitra: true,
                kormo: false,
                limit: 50,
                page: 1,
            },
            userForm: {
                fullname: '',
                email: '',
                password: '',
                phone_number: '',
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                user_verified: false,
                verification_code: null,
                login_via_email: true
            },
            inviteForm: {
                group_id: null,
                user_follower_id: ''
            },
            query: '',
            filterType: null,
            role_options: [
                {name: 'Candidate', value: ['zxc123']},
                {name: 'Employer', value: ['asd123']},
            ],
            group_options: [],
            company_options: [],
            office_options: [],
            partner_options: [],
            internal_role_options: [],
            isLoading: true,
            isFetching: false,
            formIsEdit: false,
            reportUser: null,
            json: {
                'Full Name': 'fullname',
                'Phone': 'phone_number',
                'Email': 'email',
                'Register At': 'created_at',
                'Address': 'address'
            }
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable
    },
    computed : {
        ...mapGetters({
            users: 'user/users',
            companies: 'company/companies',
            company: 'company/company',
            company_roles: 'company_role/company_roles',
            properties: 'property/properties',
        }),
        filterText() {
            let total = 0;
            if (this.meta.fullname.length > 0) {
                total++;
            }
            if (this.meta.email.length > 0) {
                total++;
            }
            if (this.meta.phone_number.length > 0) {
                total++;
            }
            if (this.meta.educations == true) {
                total++;
            }
            if (this.meta.work_experiences == true) {
                total++;
            }
            if (this.meta.skills == true) {
                total++;
            }
            if (this.meta.doc_ijazah == true) {
                total++;
            }
            if (this.meta.doc_ktp == true) {
                total++;
            }
            if (this.meta.doc_skck == true) {
                total++;
            }
            if (this.meta.doc_sim == true) {
                total++;
            }
            if (this.meta.kormo == true) {
                total++;
            }
            if (total!=0) {
                return `${total} `;
            } else {
                return '';
            }
        }
    },
    watch: {
        meta() {
            this.setUserMeta();
        },
    },
    mounted() {
        this.user_cred = getUserLogin();
        this.setUserMeta();
    },
    methods: {
        ...mapActions({
            getUserPartner: 'user/getUserPartner',
            createUser: 'user/createUser',
            updateUser: 'user/updateUser',
            deleteUser: 'user/deleteUser',
            getCompanies: 'company/getCompanies',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getProperties: 'property/getProperties',
            postInviteUser: 'option_data/postInviteUser',
            getGroup: 'option_data/getGroup',
        }),
        async setGroupValues(query) {
            this.isFetching = true;

            this.group_options = await this.getGroup({ name: query, user_id: this.inviteForm.user_follower_id });

            this.isFetching = false;
        },
        fullName(data) {
            return formatter.getFullname(data);
        },
        async setUserMeta() {
            this.isLoading = true;
            await this.getUserPartner(this.meta);
            window.UIkit.dropdown('#dropdown-filter').hide(false);
            this.isLoading = false;
        },
        async setPartnerValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.partner_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setRoleValues(query) {
            this.isFetching = true;

            await this.getCompanyRoles({name: query, is_internal: true});
            this.internal_role_options = this.company_roles.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.office_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        resetMeta() {
            this.meta = {
                roles: 'mitra',
                limit: 100,
                page: 1,
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        onQueryEnter() {
            this.resetMeta();
            this.meta[this.filterType] = this.query;
            this.setUserMeta();
        },
        editUser(user) {
            this.formIsEdit = true;
            this.fillForm(user);
            this.showModal();
        },
        delUser(user) {
            this.formIsEdit = true;
            this.fillForm(user);
            this.showDeleteModal();
        },
        inviteUser(user) {
            this.inviteForm.user_follower_id = user._id;
            this.group_options = [];
            this.inviteForm.group_id = null;
            this.$validator.reset();
            this.showInviteModal();
        },
        async markDelete(){
            try {
                this.isLoading = true;
                const response = await this.deleteUser(this.userForm.id);
                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User Deleted!');
                    this.hideDeleteModal();
                    this.setUserMeta();
                } else {
                    notificationDanger('Failed to delete user!');
                }
            } catch (err) {
                notificationDanger(err);
            }

        },
        async saveInvite() {
            try {
                this.isLoading = true;

                this.inviteForm.group_id = this.inviteForm.group_id._id;
                const response = await this.postInviteUser(this.inviteForm);

                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('User invited!');
                    this.hideInviteModal();
                } else {
                    notificationDanger('Failed to invite user.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async saveUser() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.userForm.roles = this.userForm.roles.value;
                this.userForm.company_id = this.userForm.company_id ? this.userForm.company_id.value : null;
                this.userForm.client_role_id = this.userForm.client_role_id ? this.userForm.client_role_id.value : null;
                this.userForm.client_office_id = this.userForm.client_office_id ? this.userForm.client_office_id.value : null;
                this.userForm.partner_ids = this.userForm.partner_ids.map(
                    partner => partner.value
                );
                if (!this.userForm.password) delete this.userForm.password;
                if (!this.userForm.verification_code) delete this.userForm.verification_code;
                if (!this.userForm.email) this.userForm.email = null;
                if (!this.userForm.phone_number) this.userForm.phone_number = null;

                const response = this.formIsEdit
                    ? await this.updateUser(this.userForm)
                    : await this.createUser(this.userForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User saved!');
                    this.hideModal();
                    this.setUserMeta();
                } else {
                    notificationDanger('Failed to save user.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showModal() {
            await window.UIkit.modal('#formUserModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formUserModal').hide();
            this.resetForm();
        },
        async showDeleteModal() {
            await window.UIkit.modal('#formDetailModal').show();
        },
        async showInviteModal() {
            await window.UIkit.modal('#formInviteModal').show();
        },
        async hideInviteModal() {
            await window.UIkit.modal('#formInviteModal').hide();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#formDetailModal').hide();
            this.resetForm();
        },
        fillForm(user) {
            try {
                this.userForm.id = user._id;
                this.userForm.fullname = user.fullname;
                this.userForm.email = user.email;
                this.userForm.phone_number = user.phone_number;
            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.userForm = {
                fullname: '',
                email: '',
                password: '',
                phone_number: '',
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                user_verified: false,
                verification_code: null,
                login_via_email: true
            };
            this.$validator.reset();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        detailUser(id) {
            this.$router.push(`/admin/candidate/detail/${id}`);
        },
        async resetFilter() {
            this.meta.fullname = '';
            this.meta.email = '';
            this.meta.phone_number = '';
            this.meta.educations = false;
            this.meta.work_experiences = false;
            this.meta.skills = false;
            this.meta.doc_skck = false;
            this.meta.doc_ktp = false;
            this.meta.doc_ijazah = false;
            this.meta.doc_sim = false;
            this.meta.kormo = false;
            await this.setUserMeta();
        }
    },
};
</script>
